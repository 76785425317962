<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="9" md="6" class="text-center">
        <v-card>
          <v-card-title>Recuperación de contraseña</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="recoverPassword">
              <v-text-field
                v-model="email"
                label="Correo electrónico"
                prepend-icon="fas fa-envelope"
              ></v-text-field>
              <v-btn 
                color="#561C24"
                elevation="3"
                variant="outlined"
                type="submit">Recuperar contraseña</v-btn
                >
              <br>
              <br>
              <v-btn text to="login" variant="plain" class="text-capitalize">Regresar al inicio de sesión</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      email: "",
    };
  },
  methods: {
    recoverPassword() {
      // Lógica para recuperar contraseña
    },
  },
};
</script>

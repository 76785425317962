function test() {
  window.alert("¡Hola mundo!");
}

async function databaseLoadItems(request) {
  var temp;
  // Petición GET al backend
  await fetch(request)
    .then((response) => response.json())
    .then((data) => {
      temp = data.body;
    })
    .catch((error) => {
      temp = error;
    });
  return temp;
}

async function dataBaseAddUpdateItem(request, item) {
  var temp;
  try {
    const response = await fetch(request, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Establecer el tipo de contenido adecuado
      },
      body: JSON.stringify(item), // Convertir el objeto de datos a formato JSON
    });
    temp = await response.json(); // Convertir la respuesta a JSON
  } catch (error) {
    temp = error;
  }
  return temp;
}

async function dataBaseDeleteItem(id, request) {
  var temp;
  try {
    const response = await fetch(request, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json", // Establecer el tipo de contenido adecuado
      },
      body: JSON.stringify({ id: id }), // Convertir el objeto de datos a formato JSON
    });
    temp = await response.json(); // Convertir la respuesta a JSON
  } catch (error) {
    temp = error;
  }
  return temp;
}

async function evaluations_per_user(request) {
  var temp;
  // Petición GET al backend
  await fetch(request)
    .then((response) => response.json())
    .then((data) => {
      temp = data.body;
    })
    .catch((error) => {
      temp = error;
    });
  return temp;
}

async function q_f_per_user_per_evaluation(request) {
  var temp;
  await fetch(request)
    .then((response) => response.json())
    .then((data) => {
      temp = data.body;
    })
    .catch((error) => {
      temp = error;
    });
  return temp;
}

async function databaseLogin(request) {
  var temp;
  await fetch(request)
    .then((response) => response.json())
    .then((data) => {
      temp = data.body;
    })
    .catch((error) => {
      temp = error;
    });
  return temp;
}

async function select_users(request) {
  var temp;
  // Petición GET al backend
  await fetch(request)
    .then((response) => response.json())
    .then((data) => {
      temp = data.body;
    })
    .catch((error) => {
      temp = error;
    });
  return temp;
}

function getFormattedDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  let month = currentDate.getMonth() + 1;
  month = month < 10 ? `0${month}` : month;
  let day = currentDate.getDate();
  day = day < 10 ? `0${day}` : day;
  let hours = currentDate.getHours();
  hours = hours < 10 ? `0${hours}` : hours;
  let minutes = currentDate.getMinutes();
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${year}-${month}-${day}_${hours}-${minutes}`;
}

async function databaseUsernameExist(request) {
  var temp;
  // Petición GET al backend
  await fetch(request)
    .then((response) => response.json())
    .then((data) => {
      temp = data.body;
    })
    .catch((error) => {
      temp = error;
    });
  return temp;
}

async function databaseSelectPhoneAndEmail(request) {
  var temp;
  // Petición GET al backend
  await fetch(request)
    .then((response) => response.json())
    .then((data) => {
      temp = data;
    })
    .catch((error) => {
      temp = error;
    });
  return temp;
}

export {
  test,
  databaseLoadItems,
  dataBaseAddUpdateItem,
  dataBaseDeleteItem,
  evaluations_per_user,
  q_f_per_user_per_evaluation,
  databaseLogin,
  select_users,
  getFormattedDate,
  databaseUsernameExist,
  databaseSelectPhoneAndEmail,
};

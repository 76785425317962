<template>
  <v-container>
    <v-breadcrumbs :items="breadCrumbsItems">
      <template v-slot:prepend>
        <v-icon icon="mdi mdi-format-list-bulleted" size="small"></v-icon>
      </template>
    </v-breadcrumbs>
    <v-card title="Usuarios" class="elevation-3">
      <template v-slot:text>
        <v-text-field
          v-model="search"
          label="Buscar"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          hide-details
          single-line
        ></v-text-field>
      </template>
      <v-data-table
        :headers="headers"
        :items="itemsUsersTemp"
        :loading="loading"
        :search="search"
        @update:options="loadData"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Registros</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-dialog v-model="dialogForm" max-width="90%">
              <template v-slot:activator="{ props }">
                <v-btn
                  class="mb-2"
                  color="black"
                  dark
                  v-bind="props"
                  append-icon="mdi-plus-circle"
                  size="small"
                >
                  Agregar
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ dialogFormTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6" sm="6" hidden>
                        <v-text-field
                          v-model="selectedItem.id"
                          label="id"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          v-model="selectedItem.instance_name"
                          label="instance_name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          v-model="selectedItem.email"
                          label="email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          v-model="selectedItem.phone_number"
                          label="phone_number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          v-model="selectedItem.image_path"
                          label="image_path"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          v-model="selectedItem.color"
                          label="color"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          v-model="selectedItem.user_name"
                          label="user_name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          v-model="selectedItem.encrypted_password"
                          label="encrypted_password"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-select
                          v-model="selectedItem.boolean_admin_permissions"
                          :items="selectAdminPermissions"
                          label="Permisos de administrador"
                          outlined
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-select
                          v-model="selectedItem.boolean_status"
                          :items="selectBooleanStatus"
                          label="Estado"
                          outlined
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="grey-darken-2"
                    variant="text"
                    @click="closeDialogForm"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="grey-darken-2"
                    variant="text"
                    @click="confirmDialogForm"
                  >
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="90%">
              <v-card>
                <v-card-title class="text-h5"
                  >¿Estás seguro de que quieres eliminar este
                  registro?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="grey-darken-2"
                    variant="text"
                    @click="closeDialogDelete"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    color="grey-darken-2"
                    variant="text"
                    @click="confirmDialogDelete"
                    >Borrado logico</v-btn
                  >
                  <v-btn
                    color="red-accent-4"
                    variant="text"
                    @click="permanentlyDelete"
                    >Borrado definitivo</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogView" max-width="90%">
              <v-card>
                <v-card-title class="text-h5"
                  >id: {{ selectedItem.id }}</v-card-title
                >
                <v-card-text>
                  instance_name:<br />
                  {{ selectedItem.instance_name }} <br /><br />
                  email:<br />
                  {{ selectedItem.email }} <br /><br />
                  phone_number:<br />
                  {{ selectedItem.phone_number }} <br /><br />
                  image_path:<br />
                  {{ selectedItem.image_path }} <br /><br />
                  color:<br />
                  {{ selectedItem.color }} <br /><br />
                  user_name:<br />
                  {{ selectedItem.user_name }} <br /><br />
                  encrypted_password:<br />
                  {{ selectedItem.encrypted_password }} <br /><br />
                  boolean_admin_permissions:<br />
                  {{ selectedItem.boolean_admin_permissions }} <br /><br />
                  boolean_status:<br />
                  {{ selectedItem.boolean_status }} <br /><br />
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="grey-darken-2"
                    variant="text"
                    @click="closeDialogView"
                    >Cerrar</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.image_path`]="{ value }">
            <v-img :src="this.$store.state.apiUrl + 'images/' +value" alt="..." style="max-width: 40px; height: auto;" cover/>
        </template>
        <template v-slot:[`item.color`]="{ value }">
          <div :style="{ color: value }">
            {{ value }}
          </div>
        </template>
        <template v-slot:[`item.boolean_admin_permissions`]="{ value }">
          <v-chip
            :color="value === 'si' ? 'red' : 'green'"
            :text="value.toString()"
            class="text-uppercase"
            size="small"
            label
          ></v-chip>
        </template>
        <template v-slot:[`item.boolean_status`]="{ value }">
          <v-chip
            :color="value === 'activo' ? 'green' : 'red'"
            :text="value.toString()"
            class="text-uppercase"
            size="small"
            label
          ></v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div style="width: max-content">
            <v-icon class="mx-1" size="small" @click="openDialogForm(item)">
              mdi-pencil
            </v-icon>
            <v-icon class="mx-1" size="small" @click="openDialogDelete(item)">
              mdi-delete
            </v-icon>
            <v-icon class="mx-1" size="small" @click="openDialogView(item)">
              mdi-eye
            </v-icon>
          </div>
        </template>
        <template v-slot:no-data>
          Datos no disponibles
          <br />
          <v-btn
            append-icon="mdi-refresh-circle"
            variant="outlined"
            @click="loadData"
          >
            Actualizar
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { databaseLoadItems } from "@/assets/js/index.js";
import { dataBaseAddUpdateItem } from "@/assets/js/index.js";
import { dataBaseDeleteItem } from "@/assets/js/index.js";
export default {
  data() {
    return {
      itemsUsers: [],
      itemsUsersTemp: [],
      headers: [
        { title: "ID", key: "id" },
        { title: "Nombre de instancia", key: "instance_name" },
        { title: "Email", key: "email" },
        { title: "Teléfono", key: "phone_number" },
        { title: "Imagen", key: "image_path" },
        { title: "Color", key: "color" },
        { title: "Nombre de usuario", key: "user_name" },
        { title: "Contraseña encriptada", key: "encrypted_password" },
        {
          title: "Permisos de administrador",
          key: "boolean_admin_permissions",
        },
        { title: "Estado activo", key: "boolean_status" },
        { title: "Acciones", key: "actions", sortable: false },
      ],
      selectAdminPermissions: [
        { title: "Si", value: 1 },
        { title: "No", value: 0 },
      ],
      selectBooleanStatus: [
        { title: "Activo", value: 1 },
        { title: "Inactivo", value: 0 },
      ],
      loading: false,
      search: "",
      dialogForm: false,
      dialogDelete: false,
      dialogView: false,
      selectedItem: {},
      apiUrl: '',
      breadCrumbsItems: [
        {
          title: "Lista de instancias evaluadas",
          disabled: false,
          href: "/#/instancias-evaluadas",
        },
        {
          title: "Lista de CRUD's",
          disabled: false,
          href: "/#/lista-CRUDs",
        },
        {
          title: "CRUD Usuarios",
          disabled: true,
        },
      ],
    };
  },
  async mounted() {},
  computed: {
    dialogFormTitle() {
      return this.selectedItem.id === undefined
        ? "Agregar registro"
        : "Editar registro";
    },
  },
  watch: {
    dialogForm(val) {
      val || this.closeDialogForm();
    },
    dialogDelete(val) {
      val || this.closeDialogDelete();
    },
    dialogView(val) {
      val || this.closeDialogView();
    },
  },
  methods: {
    openDialogForm(item) {
      for (const itemUser of this.itemsUsers) {
        if (item.id === itemUser.id) {
          this.selectedItem = structuredClone(itemUser);
          break;
        }
      }
      this.dialogForm = true;
    },

    openDialogView(item) {
      this.selectedItem = Object.assign({}, item);
      this.dialogView = true;
    },

    openDialogDelete(item) {
      for (const itemUser of this.itemsUsers) {
        if (item.id === itemUser.id) {
          this.selectedItem = structuredClone(itemUser);
          break;
        }
      }
      this.dialogDelete = true;
    },

    closeDialogForm() {
      this.dialogForm = false;
      this.$nextTick(() => {
        this.selectedItem = Object.assign({});
      });
    },

    closeDialogDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.selectedItem = Object.assign({});
      });
    },

    closeDialogView() {
      this.dialogView = false;
      this.$nextTick(() => {
        this.selectedItem = Object.assign({});
      });
    },

    async confirmDialogDelete() {
      this.loading = true;
      this.selectedItem.boolean_status = 0;
      await dataBaseAddUpdateItem(
        this.$store.state.apiUrl + "users/update",
        this.selectedItem
      );
      this.closeDialogDelete();
      this.loadData();
    },

    async permanentlyDelete() {
      this.loading = true;
      await dataBaseDeleteItem(
        this.selectedItem.id,
        this.$store.state.apiUrl + "users/delete_physically"
      );
      this.closeDialogDelete();
      this.loadData();
    },

    async confirmDialogForm() {
      this.loading = true;
      var request =
        this.selectedItem.id === undefined
          ? this.$store.state.apiUrl + "users/register"
          : this.$store.state.apiUrl + "users/update";
      await dataBaseAddUpdateItem(request, this.selectedItem);
      this.closeDialogForm();
      this.loadData();
    },

    async loadData() {
      //start loading
      this.loading = true;
      //load itemsUsers
      this.itemsUsers = await databaseLoadItems(
        this.$store.state.apiUrl + "users/select_all"
      );
      // asign itemsUsersTemp
      this.itemsUsersTemp = structuredClone(this.itemsUsers);
      // edit itemsUsersTemp
      for (const itemUser of this.itemsUsersTemp) {
        // itemsUsersTemp - boolean_admin_permissions
        if (itemUser.boolean_admin_permissions === 1) {
          itemUser.boolean_admin_permissions = "si";
        } else if (itemUser.boolean_admin_permissions === 0) {
          itemUser.boolean_admin_permissions = "no";
        }
        // itemsUsersTemp - boolean_status
        if (itemUser.boolean_status === 1) {
          itemUser.boolean_status = "activo";
        } else if (itemUser.boolean_status === 0) {
          itemUser.boolean_status = "inactivo";
        }
      }
      //setTimeout and stop loading
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>

<template>
  <v-app>
    <AppHeader />
    <v-main>
      <v-container fluid class="py-0 px-0">
        <router-view/>
      </v-container>
    </v-main>
    <AppFooter />
    <AppCopyRights />
  </v-app>
</template>

<script>
import AppFooter from '@/components/AppFooter'
import AppHeader from '@/components/AppHeader'
import AppCopyRights from '@/components/AppCopyRights'
export default {
  name: 'AppVue',
  components: { AppHeader, AppFooter, AppCopyRights }
}
</script>

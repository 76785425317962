<!--itemsFiles (4)-->
<!--loadData (7)-->
<!--/files/ (6)-->
<template>
  <v-container>
    <v-breadcrumbs :items="breadCrumbsItems">
      <template v-slot:prepend>
        <v-icon icon="mdi mdi-format-list-bulleted" size="small"></v-icon>
      </template>
    </v-breadcrumbs>
    <v-card title="Archivos" class="elevation-3">
      <template v-slot:text>
        <v-text-field
          v-model="search"
          label="Buscar"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          hide-details
          single-line
        ></v-text-field>
      </template>
      <v-data-table
        :headers="headers"
        :items="itemsFilesTemp"
        :loading="loading"
        :search="search"
        @update:options="loadData"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Registros</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-dialog v-model="dialogForm" max-width="90%">
              <template v-slot:activator="{ props }">
                <v-btn
                  class="mb-2"
                  color="black"
                  dark
                  v-bind="props"
                  append-icon="mdi-plus-circle"
                  size="small"
                >
                  Agregar
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ dialogFormTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6" sm="6" hidden>
                        <v-text-field
                          v-model="selectedItem.id"
                          label="id"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          v-model="selectedItem.file_path"
                          label="file_path"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-select
                          v-model="selectedItem.user_question_id"
                          :items="selectUserQuestionId"
                          label="user_question_id"
                          outlined
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-select
                          v-model="selectedItem.boolean_status"
                          :items="selectBooleanStatus"
                          label="Estado"
                          outlined
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="grey-darken-2"
                    variant="text"
                    @click="closeDialogForm"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="grey-darken-2"
                    variant="text"
                    @click="confirmDialogForm"
                  >
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="90%">
              <v-card>
                <v-card-title class="text-h5"
                  >¿Estás seguro de que quieres eliminar este
                  registro?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="grey-darken-2"
                    variant="text"
                    @click="closeDialogDelete"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    color="grey-darken-2"
                    variant="text"
                    @click="confirmDialogDelete"
                    >Borrado logico</v-btn
                  >
                  <v-btn
                    color="red-accent-4"
                    variant="text"
                    @click="permanentlyDelete"
                    >Borrado definitivo</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogView" max-width="90%">
              <v-card>
                <v-card-title class="text-h5"
                  >id: {{ selectedItem.id }}</v-card-title
                >
                <v-card-text>
                  file_path:<br />
                  {{ selectedItem.file_path }} <br /><br />
                  user_question_id:<br />
                  {{ selectedItem.user_question_id }} <br /><br />
                  boolean_status:<br />
                  {{ selectedItem.boolean_status }} <br /><br />
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="grey-darken-2"
                    variant="text"
                    @click="closeDialogView"
                    >Cerrar</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.boolean_status`]="{ value }">
          <v-chip
            :color="value === 'activo' ? 'green' : 'red'"
            :text="value.toString()"
            class="text-uppercase"
            size="small"
            label
          ></v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div style="width: max-content">
            <v-icon class="mx-1" size="small" @click="openDialogForm(item)">
              mdi-pencil
            </v-icon>
            <v-icon class="mx-1" size="small" @click="openDialogDelete(item)">
              mdi-delete
            </v-icon>
            <v-icon class="mx-1" size="small" @click="openDialogView(item)">
              mdi-eye
            </v-icon>
          </div>
        </template>
        <template v-slot:no-data>
          Datos no disponibles
          <br />
          <v-btn
            append-icon="mdi-refresh-circle"
            variant="outlined"
            @click="loadData"
          >
            Actualizar
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { databaseLoadItems } from "@/assets/js/index.js";
import { dataBaseAddUpdateItem } from "@/assets/js/index.js";
import { dataBaseDeleteItem } from "@/assets/js/index.js";
export default {
  data() {
    return {
      itemsFiles: [],
      itemsFilesTemp: [],
      itemsUserQuestions: [],
      headers: [
        { title: "ID", key: "id" },
        { title: "Ruta del archivo", key: "file_path" },
        { title: "User_question_id", key: "user_question_id" },
        { title: "Estado activo", key: "boolean_status" },
        { title: "Acciones", key: "actions", sortable: false },
      ],
      selectBooleanStatus: [
        { title: "Activo", value: 1 },
        { title: "Inactivo", value: 0 },
      ],
      selectUserQuestionId: [],
      loading: false,
      search: "",
      dialogForm: false,
      dialogDelete: false,
      dialogView: false,
      selectedItem: {},
      breadCrumbsItems: [
        {
          title: "Lista de instancias evaluadas",
          disabled: false,
          href: "/#/instancias-evaluadas",
        },
        {
          title: "Lista de CRUD's",
          disabled: false,
          href: "/#/lista-CRUDs",
        },
        {
          title: "CRUD Archivos",
          disabled: true,
        },
      ],
    };
  },
  mounted() {},
  computed: {
    dialogFormTitle() {
      return this.selectedItem.id === undefined
        ? "Agregar registro"
        : "Editar registro";
    },
  },
  watch: {
    dialogForm(val) {
      val || this.closeDialogForm();
    },
    dialogDelete(val) {
      val || this.closeDialogDelete();
    },
    dialogView(val) {
      val || this.closeDialogView();
    },
  },
  methods: {
    openDialogForm(item) {
      for (const itemFiles of this.itemsFiles) {
        if (item.id === itemFiles.id) {
          this.selectedItem = structuredClone(itemFiles);
          break;
        }
      }
      this.dialogForm = true;
    },

    openDialogView(item) {
      this.selectedItem = Object.assign({}, item);
      this.dialogView = true;
    },

    openDialogDelete(item) {
      for (const itemFiles of this.itemsFiles) {
        if (item.id === itemFiles.id) {
          this.selectedItem = structuredClone(itemFiles);
          break;
        }
      }
      this.dialogDelete = true;
    },

    closeDialogForm() {
      this.dialogForm = false;
      this.$nextTick(() => {
        this.selectedItem = Object.assign({});
      });
    },

    closeDialogDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.selectedItem = Object.assign({});
      });
    },

    closeDialogView() {
      this.dialogView = false;
      this.$nextTick(() => {
        this.selectedItem = Object.assign({});
      });
    },

    async confirmDialogDelete() {
      this.loading = true;
      this.selectedItem.boolean_status = 0;
      await dataBaseAddUpdateItem(
        this.$store.state.apiUrl + "files/update",
        this.selectedItem
      );
      this.closeDialogDelete();
      this.loadData();
    },

    async permanentlyDelete() {
      this.loading = true;
      await dataBaseDeleteItem(
        this.selectedItem.id,
        this.$store.state.apiUrl + "files/delete_physically"
      );
      this.closeDialogDelete();
      this.loadData();
    },

    async confirmDialogForm() {
      this.loading = true;
      var request =
        this.selectedItem.id === undefined
          ? this.$store.state.apiUrl + "files/add"
          : this.$store.state.apiUrl + "files/update";
      await dataBaseAddUpdateItem(request, this.selectedItem);
      this.closeDialogForm();
      this.loadData();
    },

    async loadData() {
      //start loading
      this.loading = true;
      //load itemsFiles
      this.itemsFiles = await databaseLoadItems(
        this.$store.state.apiUrl + "files/select_all"
      );
      //load itemsUserQuestions
      this.itemsUserQuestions = await databaseLoadItems(
        this.$store.state.apiUrl + "user_questions/select_all"
      );
      // asign itemsFilesTemp
      this.itemsFilesTemp = structuredClone(this.itemsFiles);
      // edit itemsFilesTemp
      for (const itemFile of this.itemsFilesTemp) {
        // itemsFilesTemp - user_question_id
        for (const itemsUserQuestion of this.itemsUserQuestions) {
          if (itemFile.user_question_id === itemsUserQuestion.id) {
            itemFile.user_question_id =
              itemsUserQuestion.id +
              ".- user_id(" +
              itemsUserQuestion.user_id +
              "), question_id(" +
              itemsUserQuestion.question_id +
              ")";
            break;
          }
        }
        // itemsFilesTemp - boolean_status
        if (itemFile.boolean_status === 1) {
          itemFile.boolean_status = "activo";
        } else if (itemFile.boolean_status === 0) {
          itemFile.boolean_status = "inactivo";
        }
      }
      // selectUserQuestionId - v-select
      this.selectUserQuestionId = structuredClone([]);
      for (const itemsUserQuestion of this.itemsUserQuestions) {
        this.selectUserQuestionId.push({
          title:
            itemsUserQuestion.id +
            ".- user_id(" +
            itemsUserQuestion.user_id +
            "), question_id(" +
            itemsUserQuestion.question_id +
            ")",
          value: itemsUserQuestion.id,
        });
      }
      //setTimeout and stop loading
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>

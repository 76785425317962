import { createRouter, createWebHashHistory } from 'vue-router'
// 404 Page
import Page404 from '@/views/Page404.vue'
// InsufficientPermissions
import InsufficientPermissions from '@/views/InsufficientPermissions.vue'
// DataBaseError
import DataBaseError from '@/views/DataBaseError.vue'
// auth Pages
import Register from '@/views/auth/Register.vue'
import Login from '@/views/auth/Login.vue'
import PasswordRecovery from '@/views/auth/PasswordRecovery.vue'
// admin Pages
import EvaluatedInstances from '@/views/UsuarioEvaluador/EvaluatedInstances.vue'
import HomeRoot from '@/views/UsuarioRoot/HomeRoot.vue'
import CrudUsers from '@/views/UsuarioRoot/CrudUsers.vue'
import CrudEvaluations from '@/views/UsuarioRoot/CrudEvaluations.vue'
import CrudFiles from '@/views/UsuarioRoot/CrudFiles.vue'
import CrudSections from '@/views/UsuarioRoot/CrudSections.vue'
import CrudQuestions from '@/views/UsuarioRoot/CrudQuestions.vue'
import CrudUserQuestions from '@/views/UsuarioRoot/CrudUserQuestions.vue'
// auth shared Pages
import EvaluationsList from '@/views/SharedUI/EvaluationsList.vue'
import UserEvaluation from '@/views/SharedUI/UserEvaluation.vue'
// vuex store
import store from '@/store'

const routes = [
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/',
    redirect: { name: 'Login' } // Redirige la ruta raíz a '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/password-recovery',
    name: 'PasswordRecovery',
    component: PasswordRecovery
  },
  {
    path: '/lista-de-evaluaciones',
    name: 'EvaluationsList',
    component: EvaluationsList
  },
  {
    path: '/instancias-evaluadas',
    name: 'EvaluatedInstances',
    component: EvaluatedInstances
  },
  {
    path: '/lista-CRUDs',
    name: 'HomeRoot',
    component: HomeRoot
  },
  {
    path: '/CrudUsers',
    name: 'CrudUsers',
    component: CrudUsers
  },
  {
    path: '/CrudEvaluations',
    name: 'CrudEvaluations',
    component: CrudEvaluations
  },
  {
    path: '/CrudFiles',
    name: 'CrudFiles',
    component: CrudFiles
  },
  {
    path: '/CrudSections',
    name: 'CrudSections',
    component: CrudSections
  },
  {
    path: '/CrudQuestions',
    name: 'CrudQuestions',
    component: CrudQuestions
  },
  {
    path: '/CrudUserQuestions',
    name: 'CrudUserQuestions',
    component: CrudUserQuestions
  },
  {
    path: '/evaluacion-del-usuario',
    name: 'UserEvaluation',
    component: UserEvaluation
  },
  {
    path: '/404',
    name: 'Page404',
    component: Page404
  },
  {
    path: '/permisos-insuficientes',
    name: 'InsufficientPermissions',
    component: InsufficientPermissions
  },
  {
    path: '/error-interno',
    name: 'DataBaseError',
    component: DataBaseError
  },
  // Non-existent routes
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'Page404' }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  //
  if (to.name === 'Login') {
    return next()
  }  
  //
  const authSharedPages = ['EvaluationsList', 'UserEvaluation', 'InsufficientPermissions', 'Page404', 'DataBaseError']
  const publicPages = ['Login', 'Page404']
  var newPageIncludesFromArray = publicPages.includes(to.name)
  const userState = store.state.user;
  // user is not defined
  if (!newPageIncludesFromArray && userState == '') {
    return next({ name: 'Login' })
  }
  // user does not have sufficient permissions
  if (userState != '') {
    if (to.name === 'InsufficientPermissions') {
      return next()
    }
    const user = JSON.parse(userState);
    newPageIncludesFromArray = authSharedPages.includes(to.name)
    if (!newPageIncludesFromArray && user.boolean_admin_permissions == 0) { 
      return next({ name: 'InsufficientPermissions' })
    }
  }
  // continue
  next()
})

export default router

<template>
  <v-container fluid>
    <v-breadcrumbs :items="breadCrumbsItems">
      <template v-slot:prepend>
        <v-icon icon="mdi mdi-format-list-bulleted" size="small"></v-icon>
      </template>
    </v-breadcrumbs>
    <h1 class="text-uppercase text-center">Lista de CRUD's</h1>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
        v-for="option in options"
        :key="option.path"
      >
        <router-link :to="option.path" class="text-decoration-none">
          <v-card elevation="3">
            <v-card-title>{{ option.title }}</v-card-title>
          </v-card>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      options: [
        { path: "/CrudFiles", title: "Archivos" },
        { path: "/CrudUserQuestions", title: "Preguntas de usuario" },
        { path: "/CrudQuestions", title: "Preguntas" },
        { path: "/CrudSections", title: "Secciones" },
        { path: "/CrudEvaluations", title: "Evaluaciones" },
        { path: "/CrudUsers", title: "Usuarios" },
      ],
      breadCrumbsItems: [
        {
          title: "Lista de instancias evaluadas",
          disabled: false,
          href: "/#/instancias-evaluadas",
        },
        {
          title: "Lista de CRUD's",
          disabled: true,
        }
      ],
    };
  },
};
</script>

<style>
/* Estilos personalizados si es necesario */
</style>

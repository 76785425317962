import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// mdi icons
import '@mdi/font/css/materialdesignicons.min.css'; // Ensure you are using css-loader
// npm install @fortawesome/fontawesome-free
import '@fortawesome/fontawesome-free/css/all.css';
// my css file
import '@/assets/css/index.css';
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
    components,
    directives,
    icons: {
      iconfont: 'mdiSvg', // default - only for display purposes
    },
  })

createApp(App).use(vuetify).use(store).use(router).mount('#app')

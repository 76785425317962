<template>
  <v-container>
    <v-breadcrumbs :items="breadCrumbsItems">
      <template v-slot:prepend>
        <v-icon icon="mdi mdi-format-list-bulleted" size="small"></v-icon>
      </template>
    </v-breadcrumbs>
    <v-row justify="center">
      <h1 class="text-uppercase text-center">
        Términos de referencia de la evaluación
      </h1>
      <v-col v-if="boolean_empty_info" cols="12" md="10" sm="10">
        <span>Datos no disponibles o no asignados</span>
      </v-col>
      <v-col
        v-else
        v-for="item in items_evaluations_per_user"
        :key="item.evaluation_id"
        cols="12"
        md="10"
        sm="10"
      >
        <v-card
          class="mx-auto elevation-2"
          @click="redirectToUserEvaluation(item.evaluation_id)"
        >
          <v-list-item>
            <h6 class="text-h6 word_wrap_break_word">
              {{ item.evaluation_name }}
            </h6>
            <v-list-item-subtitle>
              <v-icon v-if="item.total_questions === item.approved_questions"
                >mdi mdi-check-circle-outline</v-icon
              >
              <v-icon v-else-if="item.approved_questions === 0"
                >mdi mdi-circle-outline</v-icon
              >
              <v-icon v-else>mdi mdi-alert-circle-outline</v-icon>
              {{ item.approved_questions }}/{{ item.total_questions }}
            </v-list-item-subtitle>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { evaluations_per_user } from "@/assets/js/index.js";
export default {
  name: "EvaluationsList",
  async mounted() {
    // get sessionStorage
    this.filter_user_id = sessionStorage.getItem("filter_user_id");
    this.filter_instance_name = sessionStorage.getItem("filter_instance_name");
    if (this.filter_user_id === null || this.filter_user_id === undefined) {
      if (this.getStoreUser != "") {
        if (this.getStoreUser.boolean_admin_permissions === 1) {
          this.$router.push({ name: "EvaluatedInstances" });
        } else {
          this.$router.push({ name: "Login" });
        }
      } else {
        this.$router.push({ name: "Login" });
      }
    }
    // load items_evaluations_per_user
    this.items_evaluations_per_user = await evaluations_per_user(
      this.$store.state.apiUrl +
        "evaluations_per_user/user_id/" +
        this.filter_user_id
    );
    //
    if (this.items_evaluations_per_user.error) {
      window.alert("Error en la base de datos, favor de intentar de nuevo");
      this.$router.push({ name: "DataBaseError" });
    }
    //
    if (this.items_evaluations_per_user == "") {
      this.boolean_empty_info = true;
    } else {
      this.boolean_empty_info = false;
    }
    //
    if (this.getStoreUser.boolean_admin_permissions === 1) {
      this.breadCrumbsItems = [
        {
          title: "Lista de instancias evaluadas",
          disabled: false,
          href: "/#/instancias-evaluadas",
        },
        {
          title:
            "Términos de referencia de la evaluación - " +
            this.filter_instance_name,
          disabled: true,
        },
      ];
    } else if (this.getStoreUser.boolean_admin_permissions === 0) {
      this.breadCrumbsItems = [
        {
          title: "Términos de referencia de la evaluación",
          disabled: true,
        },
      ];
    }
  },
  data() {
    return {
      items_evaluations_per_user: [],
      filter_user_id: null,
      filter_instance_name: null,
      breadCrumbsItems: [],
      boolean_empty_info: true,
    };
  },
  computed: {
    getStoreUser() {
      const userState = this.$store.state.user;
      if (userState != "") {
        const user = JSON.parse(userState);
        return user;
      } else {
        return "";
      }
    },
  },
  methods: {
    redirectToUserEvaluation(id) {
      if (id != null) {
        sessionStorage.setItem("filter_evaluation_id", id);
        this.$router.push({ name: "UserEvaluation" });
      }
    },
  },
};
</script>

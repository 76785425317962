<template>
  <v-container>
    <v-breadcrumbs :items="breadCrumbsItems">
      <template v-slot:prepend>
        <v-icon icon="mdi mdi-format-list-bulleted" size="small"></v-icon>
      </template>
    </v-breadcrumbs>
    <v-row justify="center">
      <h1 class="text-uppercase text-center">Lista de instancias evaluadas</h1>
      <v-col
        v-for="item in itemsUsers"
        :key="item.id"
        cols="12"
        md="10"
        sm="10"
      >
        <v-card
          class="mx-auto elevation-2"
          @click="redirectToEvaluations(item.id, item.instance_name)"
        >
          <v-list-item>
            <h6 class="text-h6 word_wrap_break_word">
              {{ item.instance_name }}
            </h6>
            <v-list-item-subtitle>
              {{ item.user_name }}
            </v-list-item-subtitle>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="boolean_root">
      <v-col cols="12">
        <v-btn color="#561C24" variant="text" to="/register"
          >Registrar instancias</v-btn
        >
      </v-col>
      <v-col cols="12">
        <v-btn color="#561C24" variant="text" to="/lista-CRUDs"
          >Panel administrador</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { select_users } from "@/assets/js/index.js";
export default {
  name: "EvaluatedInstances",
  data() {
    return {
      itemsUsers: {},
      breadCrumbsItems: [
        {
          title: "Lista de instancias evaluadas",
          disabled: true,
        },
      ],
      boolean_root: false,
    };
  },
  async mounted() {
    const user = JSON.parse(this.$store.state.user);
    if(user.user_name.includes('root')){
      this.boolean_root = true
    }else{
      this.boolean_root = false
    }
    await this.loadData();
  },
  methods: {
    async loadData() {
      //load itemsUsers
      this.itemsUsers = await select_users(
        this.$store.state.apiUrl + "users/select_users"
      );
    },
    redirectToEvaluations(id, instance_name) {
      if (id != null) {
        sessionStorage.setItem("filter_user_id", id);
        sessionStorage.setItem("filter_instance_name", instance_name);
        this.$router.push({ name: "EvaluationsList" });
      }
    },
  },
};
</script>
